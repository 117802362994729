/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onServiceWorkerUpdateFound = () => {
  const answer = window.confirm(
    `Die Seite wurde geupdated. ` +
      `Neuladen für die neueste Version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}